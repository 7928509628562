import './BreadCrumb.css';

const BreadCrumb = ({ title = '', list = [''] }) => {
    return (
        <div className="ltn__breadcrumb-area ltn__breadcrumb-area-4">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ltn__breadcrumb-inner text-center">
                            <h1 className="ltn__page-title text-color">{title}</h1>
                            <div className="ltn__breadcrumb-list">
                                <ul>
                                    {list.map((l: string, i) => (<li className="text-color" key={i}>{l}</li>))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BreadCrumb;
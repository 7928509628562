import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { HeaderNav, FooterNav, BreadCrumb } from 'Components';
import { ImageType, ProductType } from '../../Types';
import db from '../database.json';
import './Gallery.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';
import wechatImage from 'Images/Wechat.png';
import insImage from 'Images/Instagram.png';
import { Product, ProductOnDb } from 'Types/Product';

const loadImagesFromFolder = (resource: any): ImageType[] => {
    return resource.keys().map((item: string) => {
        const fileName = item.replace('./', '').replace('.jpg', '');
        let productType = ProductType.Unknown;

        if (fileName.startsWith('C')) {
            productType = ProductType.CustomFlowerBouquets;
        }

        if (fileName.startsWith('D')) {
            productType = ProductType.DesignerFlowerArrangement;
        }

        if (fileName.startsWith('N')) {
            productType = ProductType.SetBouquets;
        }

        if (fileName.startsWith('W')) {
            productType = ProductType.WeddingFunction;
        }

        if (fileName.startsWith('S')) {
            productType = ProductType.SpecialEdition;
        }

        return {
            name: fileName.split('-')[0],
            type: productType,
            src: resource(item).default
        } as ImageType;
    });
};

const Gallery = () => {
    const [selectProduct, setSelectProduct] = useState<Product>();
    const [allProduct, setAllProduct] = useState<Product[]>([]);
    const [showCode, setShowCode] = useState<boolean>(false);

    // filters params
    const [searchText, setSearchText] = useState<string>('');
    const [filterType, setFilterType] = useState<string>(ProductType.CustomFlowerBouquets);
    const itemsPerPage = 9;
    const [currentPage, setCurrentPage] = useState<number>(0);

    // products that matches search text
    const searchedProducts = allProduct.filter(
        p => p.productName.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
    );

    // products of given type
    const typedProducts = searchedProducts.filter(
        p => p.productType === filterType
    );

    const skip = currentPage * itemsPerPage;
    // products for current page
    const pagedProducts = typedProducts.reverse().slice(skip, skip + itemsPerPage);

    const pageNumber = Math.ceil(typedProducts.length / itemsPerPage);

    useEffect(() => {
        const allImages = loadImagesFromFolder(require.context('Images/Products', false, /\.(png|jpe?g|svg)$/));
        const productsWithImage: Product[] = [];
        (db as ProductOnDb[]).forEach((d: ProductOnDb) => {
            const matchedImages = allImages.filter(i => i.name === d.imageName);
            if (matchedImages.length > 0) {
                productsWithImage.push({
                    ...d,
                    productType: matchedImages[0].type,
                    imageSrc: matchedImages.map(i => i.src)
                } as Product);
            }
        });
        setAllProduct(productsWithImage);
    }, []);

    return (
        <>
            <Helmet>
                <title>Soulmate Flower Design - Gallery</title>
            </Helmet>
            <HeaderNav flex={false} />
            <BreadCrumb title={'List of Products'} list={['Gallery', filterType]} />

            <div className="ltn__product-area mt-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3  mb-100">
                            <aside className="sidebar ltn__shop-sidebar">

                                <div className="widget ltn__search-widget">
                                    <div>
                                        <input
                                            type="text"
                                            name="search"
                                            value={searchText}
                                            placeholder="Search your keyword..."
                                            onChange={(e) => {
                                                setCurrentPage(0);
                                                setSearchText(e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="widget ltn__menu-widget">
                                    <h4 className="ltn__widget-title">categories</h4>
                                    <ul>
                                        <li>
                                            <a onClick={() => {
                                                setFilterType(ProductType.CustomFlowerBouquets);
                                                setCurrentPage(0);
                                            }}>

                                                Custom Flower Bouquets ({searchedProducts.filter(p => p.productType === ProductType.CustomFlowerBouquets).length})
                                            </a>
                                        </li>
                                        <li>
                                            <a onClick={() => {
                                                setFilterType(ProductType.DesignerFlowerArrangement);
                                                setCurrentPage(0);
                                            }}>
                                                Designer Flower Arrangements ({searchedProducts.filter(p => p.productType === ProductType.DesignerFlowerArrangement).length})
                                            </a>
                                        </li>
                                        <li>
                                            <a onClick={() => {
                                                setFilterType(ProductType.SetBouquets);
                                                setCurrentPage(0);
                                            }}>
                                                Set Bouquets ({searchedProducts.filter(p => p.productType === ProductType.SetBouquets).length})
                                            </a>
                                        </li>
                                        <li>
                                            <a onClick={() => {
                                                setFilterType(ProductType.WeddingFunction);
                                                setCurrentPage(0);
                                            }}>
                                                Wedding Function ({searchedProducts.filter(p => p.productType === ProductType.WeddingFunction).length})
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </aside>
                        </div>
                        <div className="col-lg-9 order-lg-2 mb-100">
                            <div className="ltn__shop-options">
                                <ul>
                                    <li>
                                        <div className="showing-product-number text-right">
                                            <span>Showing {typedProducts.length} results</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="ltn__grid-list-tab-menu ">
                                            <div className="nav">
                                                <a className="active show" data-toggle="tab" href="#liton_product_grid"><i className="icon-grid" /></a>
                                                <a data-toggle="tab" href="#liton_product_list"><i className="icon-menu" /></a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="tab-content" id="liton_product_content">
                                <div className="tab-pane fade active show" id="liton_product_grid">
                                    <div className="ltn__product-tab-content-inner ltn__product-grid-view">
                                        <div className="row">
                                            {pagedProducts.map((product: Product, index: number) => (
                                                <div key={index} className="col-xl-4 col-sm-6 col-12">
                                                    <div className="ltn__product-item text-center">
                                                        <div className="gallery-product-img product-img">
                                                            <img src={product.imageSrc[0]} alt={product.productName} />
                                                            <div className="product-hover-action product-hover-action-2">
                                                                <ul>
                                                                    <li>
                                                                        <a title="Quick View"
                                                                            data-toggle="modal"
                                                                            data-target="#quick_view_modal"
                                                                            onClick={() => {
                                                                                setSelectProduct(product);
                                                                                setShowCode(false);
                                                                            }}>
                                                                            <i className="icon-magnifier" />
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="product-info">
                                                            <h2 className="product-title"><a>{product.productName}</a></h2>
                                                            <div className="product-price">
                                                                {product?.productType === ProductType.SetBouquets && <span>From ${product.price.split(',')[0]}.00 NZD</span>}
                                                                {product?.productType !== ProductType.SetBouquets && <span>{product.price}</span>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="liton_product_list">
                                    <div className="ltn__product-tab-content-inner ltn__product-list-view">
                                        <div className="row">
                                            {pagedProducts.map((product: Product, index: number) => (
                                                <div key={index} className="col-lg-12">
                                                    <div className="ltn__product-item">
                                                        <div className="product-img">
                                                            <img src={product.imageSrc[0]} alt="#" />
                                                        </div>
                                                        <div className="product-info">
                                                            <h2 className="product-title">{product?.productType} {product?.productName}</h2>
                                                            <div className="product-price">
                                                                {product?.productType === ProductType.SetBouquets && <div className="ltn__size-widget clearfix mt-25">
                                                                    <ul>
                                                                        <li><a className="d-meta-title">Small:NZD ${product.price.split(',')[0]}.00</a></li>
                                                                        <li><a className="d-meta-title">Medium:NZD ${product.price.split(',')[1]}.00</a></li>
                                                                        <li><a className="d-meta-title">Large:NZD ${product.price.split(',')[2]}.00</a></li>
                                                                    </ul>
                                                                </div>}
                                                                {product?.productType !== ProductType.SetBouquets && <span>{product.price}</span>}
                                                            </div>
                                                            <div className="product-brief">
                                                                <p>Eco-friendly flowers are a beautiful solution for the thoughtful gift giver.</p>
                                                            </div>
                                                            <div className="product-hover-action product-hover-action-2">
                                                                <ul>
                                                                    <li className="add-to-cart">
                                                                        <a data-toggle="modal"
                                                                            data-target="#quick_view_modal"
                                                                            onClick={() => {
                                                                                setSelectProduct(product);
                                                                                setShowCode(false);
                                                                            }}>
                                                                            <span className="cart-text d-none d-xl-block">View Detial</span>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {pageNumber > 1 && (
                                <div className="ltn__pagination-area text-center">
                                    <div className="ltn__pagination ltn__pagination-2">
                                        <ul>
                                            <li>
                                                <a onClick={() => {
                                                    if (currentPage > 0) {
                                                        setCurrentPage(currentPage - 1);
                                                    }
                                                }}><i className="icon-arrow-left" /></a>
                                            </li>
                                            {
                                                Array.from(Array(pageNumber).keys())
                                                    .map(i => (
                                                        <li key={i} className={(currentPage === i) ? 'active' : ''}>
                                                            <a onClick={() => setCurrentPage(i)}>{i + 1}</a>
                                                        </li>
                                                    ))
                                            }
                                            <li>
                                                <a onClick={() => {
                                                    if (currentPage + 1 < pageNumber) {
                                                        setCurrentPage(currentPage + 1);
                                                    }
                                                }}><i className="icon-arrow-right" /></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>

            <FooterNav />

            <div className="ltn__modal-area ltn__quick-view-modal-area">
                <div className="modal fade" id="quick_view_modal" tabIndex={-1}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    <i className="fas fa-times" />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="ltn__quick-view-modal-inner">
                                    <div className="modal-product-item">
                                        <div className="row" style={{ marginTop: 20 }}>
                                            <div className="col-lg-6 col-12">
                                                <div className="modal-product-img" style={{ minHeight: 100 }} >
                                                    {((selectProduct?.imageSrc?.length ?? 0) <= 1)
                                                        ? <img src={selectProduct?.imageSrc[0]} alt="#" />
                                                        : <ImageGallery showThumbnails
                                                            showFullscreenButton={false}
                                                            thumbnailPosition={'left'}
                                                            showPlayButton={false}
                                                            showNav={false}
                                                            items={selectProduct!.imageSrc.map(src => {
                                                                return {
                                                                    original: src,
                                                                    thumbnail: src
                                                                };
                                                            })} />

                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="modal-product-info shop-details-info pl-0">
                                                    <h3>{selectProduct?.productName}</h3>
                                                    <h5 className='mt-10'>{selectProduct?.productType}</h5>
                                                    {selectProduct?.productType === ProductType.SetBouquets &&
                                                        <div className="modal-product-meta ltn__product-details-menu-1 mb-20">
                                                            <ul>
                                                                <li>
                                                                    <div className="ltn__size-widget clearfix mt-25">
                                                                        <ul>
                                                                            <li><a className="d-meta-title">Small:NZD ${selectProduct?.price.split(',')[0]}.00</a></li>
                                                                            <li><a className="d-meta-title">Medium:NZD ${selectProduct?.price.split(',')[1]}.00</a></li>
                                                                            <li><a className="d-meta-title">Large:NZD ${selectProduct?.price.split(',')[2]}.00</a></li>
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    }
                                                    {selectProduct?.productType !== ProductType.SetBouquets &&
                                                        <div className="product-price-ratting mb-20">
                                                            <ul>
                                                                <li>
                                                                    <div className="product-price">
                                                                        <span>{selectProduct?.price}</span>
                                                                    </div>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    }
                                                    <div className="modal-product-brief">
                                                        <p>Eco-friendly flowers are a beautiful solution for the thoughtful gift giver.</p>
                                                    </div>
                                                    {!!!showCode && <div className="ltn__product-details-menu-2 product-cart-wishlist-btn mb-30">
                                                        <ul>
                                                            <li>
                                                                <a onClick={() => setShowCode(true)} className="theme-btn-1 btn btn-effect-1 d-add-to-cart">
                                                                    <span>Order Now</span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>}
                                                    {showCode && <div className='qr-row'>
                                                        <img className='code-image' src={insImage} />
                                                        <img className='code-image' src={wechatImage} />
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Gallery;
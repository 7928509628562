import m2hs from 'Images/BusinessClient/M2HairSalon/M2HairSalon.jpg';
import m2hs1 from 'Images/BusinessClient/M2HairSalon/M2HairSalon-1.jpg';
import m2hs2 from 'Images/BusinessClient/M2HairSalon/M2HairSalon-2.jpg';
import m2hs3 from 'Images/BusinessClient/M2HairSalon/M2HairSalon-3.jpg';
import m2hs4 from 'Images/BusinessClient/M2HairSalon/M2HairSalon-4.jpg';
import m2hs5 from 'Images/BusinessClient/M2HairSalon/M2HairSalon-5.jpg';

import ag from 'Images/BusinessClient/AutoGravity/AutoGravity.jpg';
import ag1 from 'Images/BusinessClient/AutoGravity/AutoGravity-1.jpg';
import ag2 from 'Images/BusinessClient/AutoGravity/AutoGravity-2.jpg';
import ag3 from 'Images/BusinessClient/AutoGravity/AutoGravity-3.jpg';
import ag4 from 'Images/BusinessClient/AutoGravity/AutoGravity-4.jpg';

import gc from 'Images/BusinessClient/GraftCafe/GraftCafe.jpg';
import gc1 from 'Images/BusinessClient/GraftCafe/GraftCafe-1.jpg';
import gc2 from 'Images/BusinessClient/GraftCafe/GraftCafe-2.jpg';
import gc3 from 'Images/BusinessClient/GraftCafe/GraftCafe-3.jpg';
import gc4 from 'Images/BusinessClient/GraftCafe/GraftCafe-4.jpg';
import gc5 from 'Images/BusinessClient/GraftCafe/GraftCafe-5.jpg';
import gc6 from 'Images/BusinessClient/GraftCafe/GraftCafe-6.jpg';
import gc7 from 'Images/BusinessClient/GraftCafe/GraftCafe-7.jpg';

import ca from 'Images/BusinessClient/CafeAvion/CafeAvion.jpg';
import ca1 from 'Images/BusinessClient/CafeAvion/CafeAvion-1.jpg';
import ca2 from 'Images/BusinessClient/CafeAvion/CafeAvion-2.jpg';
import ca3 from 'Images/BusinessClient/CafeAvion/CafeAvion-3.jpg';
import ca4 from 'Images/BusinessClient/CafeAvion/CafeAvion-4.jpg';
import ca5 from 'Images/BusinessClient/CafeAvion/CafeAvion-5.jpg';
import ca6 from 'Images/BusinessClient/CafeAvion/CafeAvion-6.jpg';
import ca7 from 'Images/BusinessClient/CafeAvion/CafeAvion-7.jpg';
import ca8 from 'Images/BusinessClient/CafeAvion/CafeAvion-8.jpg';

import dg from 'Images/BusinessClient/DGAutoLtd/DGAutoLtd.jpg';
import dg1 from 'Images/BusinessClient/DGAutoLtd/DGAutoLtd-1.jpg';
import dg2 from 'Images/BusinessClient/DGAutoLtd/DGAutoLtd-2.jpg';
import dg3 from 'Images/BusinessClient/DGAutoLtd/DGAutoLtd-3.jpg';
import dg4 from 'Images/BusinessClient/DGAutoLtd/DGAutoLtd-4.jpg';

import ppas from 'Images/BusinessClient/PaintingPenArtSchool/PaintingPenArtSchool.jpg';
import ppas1 from 'Images/BusinessClient/PaintingPenArtSchool/PaintingPenArtSchool-1.jpg';
import ppas2 from 'Images/BusinessClient/PaintingPenArtSchool/PaintingPenArtSchool-2.jpg';
import ppas3 from 'Images/BusinessClient/PaintingPenArtSchool/PaintingPenArtSchool-3.jpg';
import ppas4 from 'Images/BusinessClient/PaintingPenArtSchool/PaintingPenArtSchool-4.jpg';

const BusinessClient = () => {
    return (
        <div className="ltn__banner-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 pb--80">
                        <div className="section-title-area text-center">
                            <h1 className="section-title section-title-border">Our Business Client Gallery</h1>
                            <h5 className="plr--6">
                                We truly believe that fresh flowers will fit any industry, there will be a style that is suitable
                                for your business. What we do is understand your business, design the unique flower arrangement for you.
                            </h5>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6">
                        <div className="ltn__banner-item  text-center">
                            <div className="ltn__banner-img">
                                <a href={m2hs} data-rel="lightcase:m2hs" title="M2 Hair Salon"><img src={m2hs} /></a>
                                <a href={m2hs1} data-rel="lightcase:m2hs" title="M2 Hair Salon" />
                                <a href={m2hs2} data-rel="lightcase:m2hs" title="M2 Hair Salon" />
                                <a href={m2hs3} data-rel="lightcase:m2hs" title="M2 Hair Salon" />
                                <a href={m2hs4} data-rel="lightcase:m2hs" title="M2 Hair Salon" />
                                <a href={m2hs5} data-rel="lightcase:m2hs" title="M2 Hair Salon" />
                            </div>
                            <div className="ltn__banner-info mt-20">
                                <h5>M2 Hair Salon</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="ltn__banner-item  text-center">
                            <div className="ltn__banner-img">
                                <a href={ag} data-rel="lightcase:ag" title="Auto Gravity"><img src={ag} /></a>
                                <a href={ag1} data-rel="lightcase:ag" title="Auto Gravity" />
                                <a href={ag2} data-rel="lightcase:ag" title="Auto Gravity" />
                                <a href={ag3} data-rel="lightcase:ag" title="Auto Gravity" />
                                <a href={ag4} data-rel="lightcase:ag" title="Auto Gravity" />
                            </div>
                            <div className="ltn__banner-info mt-20">
                                <h5>Auto Gravity</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="ltn__banner-item text-center">
                            <div className="ltn__banner-img">
                                <a href={gc} data-rel="lightcase:gc" title="Graft&apos;n Cafe"><img src={gc} /></a>
                                <a href={gc1} data-rel="lightcase:gc" title="Graft&apos;n Cafe" />
                                <a href={gc2} data-rel="lightcase:gc" title="Graft&apos;n Cafe" />
                                <a href={gc3} data-rel="lightcase:gc" title="Graft&apos;n Cafe" />
                                <a href={gc4} data-rel="lightcase:gc" title="Graft&apos;n Cafe" />
                                <a href={gc5} data-rel="lightcase:gc" title="Graft&apos;n Cafe" />
                                <a href={gc6} data-rel="lightcase:gc" title="Graft&apos;n Cafe" />
                                <a href={gc7} data-rel="lightcase:gc" title="Graft&apos;n Cafe" />
                            </div>
                            <div className="ltn__banner-info mt-20">
                                <h5>Graft&apos;n Cafe</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="ltn__banner-item text-center">
                            <div className="ltn__banner-img">
                                <a href={ca} data-rel="lightcase:ca" title="Cafe Avion"><img src={ca} /></a>
                                <a href={ca1} data-rel="lightcase:ca" title="Cafe Avion" />
                                <a href={ca2} data-rel="lightcase:ca" title="Cafe Avion" />
                                <a href={ca3} data-rel="lightcase:ca" title="Cafe Avion" />
                                <a href={ca4} data-rel="lightcase:ca" title="Cafe Avion" />
                                <a href={ca5} data-rel="lightcase:ca" title="Cafe Avion" />
                                <a href={ca6} data-rel="lightcase:ca" title="Cafe Avion" />
                                <a href={ca7} data-rel="lightcase:ca" title="Cafe Avion" />
                                <a href={ca8} data-rel="lightcase:ca" title="Cafe Avion" />
                            </div>
                            <div className="ltn__banner-info mt-20">
                                <h5>Cafe Avion</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="ltn__banner-item text-center">
                            <div className="ltn__banner-img">
                                <a href={dg} data-rel="lightcase:dg" title="D&G Auto Ltd"><img src={dg} /></a>
                                <a href={dg1} data-rel="lightcase:dg" title="D&G Auto Ltd" />
                                <a href={dg2} data-rel="lightcase:dg" title="D&G Auto Ltd" />
                                <a href={dg3} data-rel="lightcase:dg" title="D&G Auto Ltd" />
                                <a href={dg4} data-rel="lightcase:dg" title="D&G Auto Ltd" />
                            </div>
                            <div className="ltn__banner-info mt-20">
                                <h5>D&G Auto Ltd</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="ltn__banner-item text-center">
                            <div className="ltn__banner-img">
                                <a href={ppas} data-rel="lightcase:ppas" title="Painting Pen Art School"><img src={ppas} /></a>
                                <a href={ppas1} data-rel="lightcase:ppas" title="Painting Pen Art School" />
                                <a href={ppas2} data-rel="lightcase:ppas" title="Painting Pen Art School" />
                                <a href={ppas3} data-rel="lightcase:ppas" title="Painting Pen Art School" />
                                <a href={ppas4} data-rel="lightcase:ppas" title="Painting Pen Art School" />
                            </div>
                            <div className="ltn__banner-info mt-20">
                                <h5>Painting Pen Art School</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BusinessClient;
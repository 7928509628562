import logo from '../../Images/logo-mini.png';
import './HeaderNav.css';

const HeaderNav = ({ flex = false }) => {

    return (
        <>
            <header className={`ltn__header-area ltn__header-8 ${flex ? 'header-area' : ''}`}>
                <div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-white">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col col-lg-8 col-sm-10">
                                <div className="site-logo">
                                    <a href="/"><img className="logo" src={logo} alt="soulmate flower" /></a>
                                    <h1 className="site-title">Soulmate Flower Design</h1>
                                </div>
                            </div>

                            <div className="col d-xl-block col-lg-3 d-sm-none  header-menu-column">
                                <div className="header-menu d-none d-xl-block">
                                    <nav>
                                        <div className="ltn__main-menu">
                                            <ul className='top-nav'>
                                                <li className="menu-icon"><a href='/'>Home</a> </li>
                                                <li className="menu-icon"><a href='/aboutus'>About Us</a> </li>
                                                <li className="menu-icon"><a href='/gallery'>Gallery</a> </li>
                                                <li className="menu-icon"><a href='/contact'>Contact</a> </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>

                            <div className="col d-xl-none col-sm-2 mobile-menu">
                                <div className="ltn__header-options">
                                    <div className="mobile-menu-toggle">
                                        <a href="#ltn__utilize-mobile-menu" className="ltn__utilize-toggle">
                                            <svg viewBox="0 0 800 600">
                                                <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top" />
                                                <path d="M300,320 L540,320" id="middle" />
                                                <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) " />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </header>

            <div id="ltn__utilize-mobile-menu" className="ltn__utilize ltn__utilize-mobile-menu">
                <div className="ltn__utilize-menu-inner ltn__scrollbar">
                    <div className="ltn__utilize-menu-head">
                        <div className="site-logo">
                            <img src={logo} alt="Logo" />
                            <span className="site-title">Soulmate Flower Design</span>
                        </div>
                    </div>
                    <div className="ltn__utilize-menu">
                        <ul>
                            <li className="menu-icon"><a href='/'>Home</a> </li>
                            <li className="menu-icon"><a href='/aboutus'>About Us</a> </li>
                            <li className="menu-icon"><a href='/gallery'>Gallery</a> </li>
                            <li className="menu-icon"><a href='/contact'>Contact</a> </li>
                            <li className="ltn__utilize-close"><a>Close</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="ltn__utilize-overlay" />
        </>
    );
};

export default HeaderNav;
import './FooterNav.css';
import wechatImage from 'Images/Wechat.png';
import insImage from 'Images/Instagram.png';

const FooterNav = () => {
    return (
        <footer className="ltn__footer-area ">
            <div className="footer-top-area  section-bg-5">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-md-6 col-sm-6 col-12">
                            <div className="footer-widget footer-about-widget">
                                <h4 className="footer-title">Soulmate Flower Studio</h4>
                                <p>The perfect bouquet can blossom romance and grow trust. Matching flowers to special occasions is our passion. We are the soulmate of flowers.</p>
                                <div className="ltn__social-media">
                                    <ul>
                                        <li className="d-meta-title">Link:</li>
                                        <li><a title="Facebook"><i className="fab fa-facebook-f" /></a></li>
                                        <li><a target="_blank" href="https://www.instagram.com/soulmate_flower/" rel="noreferrer"
                                            title="Instagram"><i className="fab fa-instagram" /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6 col-sm-6 col-12">
                            <div className="footer-widget footer-menu-widget clearfix">
                                <h4 className="footer-title">Contact Us</h4>
                                <div className="footer-address">
                                    <ul>
                                        {/* <li>
                                            <div className="footer-address-icon">
                                                <i className="icon-location-pin" />
                                            </div>
                                            <div className="footer-address-info">
                                                <p>81 Ivanhoe Road, Grey Lynn, Auckland 1021</p>
                                            </div>
                                        </li> */}
                                        <li>
                                            <div className="footer-address-icon">
                                                <i className="icon-envelope" />
                                            </div>
                                            <div className="footer-address-info">
                                                <p><a href="mailto:info@soulmateflowerdesign.co.nz">info@soulmateflowerdesign.co.nz</a></p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="footer-address-icon">
                                                <i className="icon-phone" />
                                            </div>
                                            <div className="footer-address-info">
                                                <p><a href="tel:09 2122528">09 2122528</a></p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="footer-payment-img">
                                    <img src="img/payment.png" alt="Payment Image" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-6 col-sm-6 col-12">
                            <div className="footer-widget footer-menu-widget clearfix">
                                <h4 className="footer-title text-center">Instagram</h4>
                                <div className="footer-menu">
                                    <img className='code-image' src={insImage} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-6 col-sm-6 col-12">
                            <div className="footer-widget footer-menu-widget clearfix">
                                <h4 className="footer-title text-center">Wechat</h4>
                                <div className="footer-menu">
                                    <img className='code-image' src={wechatImage} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ltn__copyright-area ltn__copyright-2 section-bg-5">
                <div className="container ltn__border-top-2">
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <div className="footer-copyright-left">
                                <div className="ltn__copyright-design clearfix">
                                    <p>&copy; 2021 - <span className="current-year" /> Product by Spaceman Studio
                                        Limited</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 align-self-center">
                            <div className="footer-copyright-right text-right">
                                <div className="ltn__copyright-menu d-none">
                                    <ul>
                                        <li><a>Terms & Conditions</a></li>
                                        <li><a>Claim</a></li>
                                        <li><a>Privacy & Policy</a></li>
                                    </ul>
                                </div>
                                <div className="ltn__social-media ">
                                    <ul>
                                        <li><a title="Facebook"><i className="icon-social-facebook" /></a></li>
                                        <li><a title="Twitter"><i className="icon-social-twitter" /></a></li>
                                        <li><a title="Instagram"><i className="icon-social-instagram" /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    );
};

export default FooterNav;
import { Helmet } from 'react-helmet';
import { BreadCrumb, FooterNav, HeaderNav } from 'Components';
import banner from 'Images/banner.jpg';

const AboutUs = () => {
    return (
        <>
            <Helmet>
                <title>Soulmate Flower Design - About Us</title>
            </Helmet>
            <HeaderNav />
            <BreadCrumb title={'About Us'} list={['Home', 'About Us']} />

            <div className="ltn__about-us-area pt-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 align-self-center">
                            <div className="about-us-info-wrap">
                                <h2 className="mb-30 font-weight-6">Soulmate Flower Studio</h2>
                                <p>The perfect bouquet can blossom romance and grow trust.
                                    Matching flowers to special occasions is our passion. We are the soulmate of flowers.</p>
                                <p>At Soulmate, we choose the freshest and most beautiful flowers available that day when fulfilling your order.
                                    That means no two flower arrangements are the same and every bouquet you receive is unique, like you.</p>
                                <p>We understand that what we sell is more than just flowers, it is messages from the heart.
                                    That’s why we’re happy to customise our floral gifts to suit your needs.
                                    From beautiful bouquets to elaborate custom arrangements for events,
                                    we can create a floral masterpiece for any occasion.</p>
                                <p>We are committed to sourcing our materials sustainably.
                                    We source our flowers from local farms and are currently in the process of connecting with local suppliers of eco-friendly wrapping materials.
                                    You can rest assured that our flower arrangements are not just good for the soul, but also great for the environment.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="ltn__banner-area pt-40">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="ltn__banner-item">
                                <div className="ltn__banner-img">
                                    <a><img src={banner} alt="Banner Image" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="ltn__faq-area pt-40 pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ltn__faq-inner ltn__faq-inner-2">
                                <div id="accordion_2">
                                    {/* <!-- card --> */}
                                    <div className="card">
                                        <h6 className="ltn__card-title" data-toggle="collapse" data-target="#faq-item-2-1" aria-expanded="true">
                                            How do I make an order?
                                        </h6>
                                        <div id="faq-item-2-1" className="collapse show" data-parent="#accordion_2">
                                            <div className="card-body">
                                                <p>
                                                    We take orders by email, phone and social media
                                                    (WeChat (scan the QR code at the bottom of our website), Instagram and Facebook).
                                                    We are also in the process of setting up an online shop.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <h6 className="collapsed ltn__card-title" data-toggle="collapse" data-target="#faq-item-2-3" aria-expanded="false">
                                            How do I pay for my order?
                                        </h6>
                                        <div id="faq-item-2-3" className="collapse" data-parent="#accordion_2">
                                            <div className="card-body">
                                                <p>Payments can be made by bank transfer. Payment must be completed within 24 hours before your scheduled delivery time.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <h6 className="collapsed ltn__card-title" data-toggle="collapse" data-target="#faq-item-2-4" aria-expanded="false">
                                            Do you take last minute orders and how much will it cost?
                                        </h6>
                                        <div id="faq-item-2-4" className="collapse" data-parent="#accordion_2">
                                            <div className="card-body">
                                                <p>
                                                    We may be able to take last minute orders, but can’t guarantee it. The price will depend on the
                                                    situation and we will let you know the cost of this before you place the order. Please get in touch
                                                    with us as soon as you can if you’d like to enquire about a last minute order.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <h6 className="collapsed ltn__card-title" data-toggle="collapse" data-target="#faq-item-2-5" aria-expanded="false">
                                            Why does the flower arrangement I received look a bit different than the picture on the website?
                                        </h6>
                                        <div id="faq-item-2-5" className="collapse" data-parent="#accordion_2">
                                            <div className="card-body">
                                                <p>
                                                    Due to the natural variation in flowers as well as differing availability of flower types,
                                                    our bouquets may differ slightly from our website photos. Each bouquet will be slightly unique which is a testament
                                                    to us choosing the freshest and most stunning flowers we have available on that day. We will always do our best to
                                                    fulfil your requirements while ensuring the bouquet is beautiful.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <h6 className="collapsed ltn__card-title" data-toggle="collapse" data-target="#faq-item-2-6" aria-expanded="false">
                                            What if my flowers are damaged during delivery?
                                        </h6>
                                        <div id="faq-item-2-6" className="collapse" data-parent="#accordion_2">
                                            <div className="card-body">
                                                <p>
                                                    Safe delivery is our top priority and we take extra care to make sure your flowers arrive undamaged.
                                                    In the unlikely event that your flower arrangement is damaged in some way,
                                                    we will provide a replacement for this as soon as possible.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <h6 className="collapsed ltn__card-title" data-toggle="collapse" data-target="#faq-item-2-2" aria-expanded="false">
                                            Delivery Fee
                                        </h6>
                                        <div id="faq-item-2-2" className="collapse" data-parent="#accordion_2">
                                            <div className="card-body">
                                                <p>
                                                    We can deliver your flower gift within 40kms from Auckland CBD.
                                                    <br />
                                                    <br />Standard delivery costs:
                                                    <br />Within 10kms from Auckland CBD we only charge $8.
                                                    <br />From 10kms to 20kms, we charge $15.
                                                    <br />More than 20kms and within 40kms is $25.
                                                    <br />
                                                    <br />We are more than happy to offer you a free delivery when you purchase more than $100 flower gift.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <h6 className="collapsed ltn__card-title" data-toggle="collapse" data-target="#faq-item-2-7" aria-expanded="false">
                                            Replace and Refund policy
                                        </h6>
                                        <div id="faq-item-2-7" className="collapse" data-parent="#accordion_2">
                                            <div className="card-body">
                                                <p>
                                                    We promised that every single flower we use is high quality, fresh and with love,
                                                    because each flower has its life cycle, there will be a minimum number of days for your flower gift to last,
                                                    we will let you know before you place an order. If your flower gift doesn’t last the same period with the correct
                                                    treatment that we suggested, we are happy to provide a replacement for you.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <FooterNav />
        </>
    );
};
export default AboutUs;
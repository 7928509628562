import { useEffect, useState } from 'react';
import './Home.css';
import { Helmet } from 'react-helmet';
import { HeaderNav, FooterNav, Slider, BusinessClient } from '../../Components';
import { Product, ProductType, ImageType } from '../../Types';
import db from '../database.json';
import wechatImage from 'Images/Wechat.png';
import insImage from 'Images/Instagram.png';
import ImageGallery from 'react-image-gallery';
import SpecialEvent from 'Components/SpecialEvent/FourColumnsBanner';
import { ProductOnDb } from 'Types/Product';

const loadImagesFromFolder = (resource: any): ImageType[] => {
    return resource.keys().map((item: string) => {
        const fileName = item.replace('./', '').replace('.jpg', '');
        let productType = ProductType.Unknown;

        if (fileName.startsWith('C')) {
            productType = ProductType.CustomFlowerBouquets;
        }

        if (fileName.startsWith('D')) {
            productType = ProductType.DesignerFlowerArrangement;
        }

        if (fileName.startsWith('N')) {
            productType = ProductType.SetBouquets;
        }

        if (fileName.startsWith('W')) {
            productType = ProductType.WeddingFunction;
        }

        if (fileName.startsWith('S')) {
            productType = ProductType.SpecialEdition;
        }

        return {
            name: fileName.split('-')[0],
            type: productType,
            src: resource(item).default
        } as ImageType;
    });
};

const Home = () => {
    const [selectProduct, setSelectProduct] = useState<Product>();
    const [allProduct, setAllProduct] = useState<Product[]>([]);
    const [showCode, setShowCode] = useState<boolean>(false);

    useEffect(() => {
        const allImages = loadImagesFromFolder(require.context('Images/Products', false, /\.(png|jpe?g|svg)$/));
        const productsWithImage: Product[] = [];
        (db as ProductOnDb[]).forEach((d: ProductOnDb) => {
            const matchedImages = allImages.filter(i => i.name === d.imageName);
            if (matchedImages.length > 0) {
                productsWithImage.push({
                    ...d,
                    productType: matchedImages[0].type,
                    imageSrc: matchedImages.map(i => i.src)
                } as Product);
            }
        });
        setAllProduct(productsWithImage);
    }, []);

    function importAll(r: any, typeText: string) {
        const results: ImageType[] = [];
        r.keys().map((item: any, _: number) => {
            const fileName = item.replace('./', '').replace('.jpg', '');
            results.push({
                name: fileName.split('-')[0],
                type: typeText,
                src: r(item).default
            });
        });
        return results;
    }
    const ad = importAll(require.context('../../Images/Ad', false, /\.(png|jpe?g|svg)$/), ProductType.Ad);

    return (
        <>
            <Helmet>‍
                <title>Soulmate Flower Design | New Zealand Auckland Flower </title>‍
                <meta name="description" content="Find all the best quality flower in New Zealand, Auckland" />
                <meta name="keywords" content="Auckland Flower, New Zealand Flower, Flower, NZ Flower" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content="Soulmate Flower Design - Auckland Flower" />
                <meta name="twitter:description" content="Find all the best quality flower in New Zealand, Auckland" />
                <meta property="og:title" content="Soulmate Flower Design - Auckland Flower" />
                <meta property="og:description" content="Find all the best quality flower in New Zealand, Auckland" />
                <meta property="og:site_name" content="Soulmate Flower Design - Auckland Flower" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
            </Helmet>
            <HeaderNav flex={false} />
            <div className="body-wrapper">
                <Slider />

                {/* <div className='section-bg-light pt-60 pb-60'>
                    <SpecialEvent />
                </div> */}

                <div className="ltn__product-tab-area section-bg pt-60 pb-60">
                    <div className="container">
                        <div className="row">
                            {/* Custom Flower Bouquets Section */}
                            <div className="col-lg-12">
                                <div className="section-title-area text-center">
                                    <h1 className="section-title section-title-border">Custom Flower Bouquets Gallery</h1>
                                    <h5 className="plr--6">
                                        If you need a flower bouquet just designed for you, yes, here is the place.
                                        Your flower gift will be different to any others before. Our florist will make unique and stylish bouquet for your special one.
                                        Not limited to occasions, we also want to satisfy the lucky one who receives the gift.
                                    </h5>
                                </div>
                                <div className="tab-content">
                                    <div className="tab-pane fade active show" id="tab_1">
                                        <div className="ltn__product-tab-content-inner">
                                            <div className="row ltn__tab-product-slider-one-active slick-arrow-1">
                                                {allProduct
                                                    .filter(p => p.productType === ProductType.CustomFlowerBouquets && !!p.displayOnHomeRank)
                                                    .sort((a, b) => (a.displayOnHomeRank ?? 0) - (b.displayOnHomeRank ?? 0))
                                                    .map((product: Product, index: number) => (
                                                        <div key={index} className="col-12">
                                                            <div className="ltn__product-item ltn__product-item-3 text-center">
                                                                <a title="Quick View"
                                                                    data-toggle="modal"
                                                                    data-target="#quick_view_modal"
                                                                    onClick={() => {
                                                                        setSelectProduct(product);
                                                                        setShowCode(false);
                                                                    }}>
                                                                    <div className="product-img">
                                                                        <img src={product.imageSrc[0]} alt="#" />
                                                                    </div>
                                                                </a>
                                                                <div className="product-info">
                                                                    <h2 className="product-title"><a>{product.productName}</a></h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Designer Flower Arrangements Section */}
                            <div className="col-lg-12">
                                <div className="section-title-area text-center">
                                    <h1 className="section-title section-title-border">Designer Flower Arrangement Gallery</h1>
                                    <h5 className="plr--6">
                                        Our designer arrangement is ideal for home, office and event decor. We select the freshest flower from the market,
                                        and make the best matching flower arrangement for your place. For this section, you could even subscribe to us
                                        and get the better price for each time.
                                    </h5>
                                </div>
                                <div className="tab-content">
                                    <div className="tab-pane fade active show" id="tab_1">
                                        <div className="ltn__product-tab-content-inner">
                                            <div className="row ltn__tab-product-slider-one-active slick-arrow-1">
                                                {allProduct
                                                    .filter(p => p.productType === ProductType.DesignerFlowerArrangement && !!p.displayOnHomeRank)
                                                    .sort((a, b) => (a.displayOnHomeRank ?? 0) - (b.displayOnHomeRank ?? 0))
                                                    .map((product: Product, index: number) => (
                                                        <div key={index} className="col-12">
                                                            <div className="ltn__product-item ltn__product-item-3 text-center">
                                                                <a title="Quick View"
                                                                    data-toggle="modal"
                                                                    data-target="#quick_view_modal"
                                                                    onClick={() => {
                                                                        setSelectProduct(product);
                                                                        setShowCode(false);
                                                                    }}>
                                                                    <div className="product-img">
                                                                        <img src={product.imageSrc[0]} alt="#" />
                                                                        <div className="product-badge">
                                                                            <ul>
                                                                                <li className="badge-1">Exclusive</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                                <div className="product-info">
                                                                    <h2 className="product-title"><a>{product.productName}</a></h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="btn-wrapper text-center">
                            <a href='/gallery' className="btn btn-transparent btn-border">View more +</a>
                        </div>
                    </div>
                </div>

                {/* Slider Images */}
                <div className='section-bg-light pt-60 pb-60'>
                    <div className="ltn__banner-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title-area text-center">
                                        <h1 className="section-title section-title-border">Soulmate Flower</h1>
                                        <h5>At Soulmate, we choose the freshest and most beautiful flowers from our local farms, and we source our materials sustainably.</h5>
                                        <h5>The flower gift you will receive is unique, like you.</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="ltn__slider-area ltn__slider-3 ltn__slider-6 plr--0">
                                        <div className="ltn__slide-ad-section slick-slide-arrow-1 slick-slide-dots-1">
                                            {ad.map((img: any, index: number) => (
                                                <div
                                                    key={index}
                                                    className="ltn__slide-item ltn__slide-item-9 bg-image"
                                                    data-bg={img.src} />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-bg pt-60 pb-60">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title-area text-center">
                                    <h1 className="section-title section-title-border">Set Bouquets Gallery</h1>
                                    <h5 className="plr--6">
                                        Less is more. Set bouquet is more casual, daily and easy. If you want something ready to go,
                                        this section is the solution for you. We choose the seasonal flower from the day, to make the
                                        bouquet beautiful and more affordable, also make your life easy.
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            {allProduct
                                .filter(p => p.productType === ProductType.SetBouquets && !!p.displayOnHomeRank)
                                .map((img: Product, index: number) => (
                                    <div key={index} className="col-lg-3 col-md-4 col-sm-6 col-12">
                                        <div className="ltn__product-item ltn__product-item-3 text-center">
                                            <a title="Quick View"
                                                data-toggle="modal"
                                                data-target="#quick_view_modal"
                                                onClick={() => {
                                                    setSelectProduct(img);
                                                    setShowCode(false);
                                                }}>
                                                <div className="product-img">
                                                    <img src={img.imageSrc[0]} alt="#" />
                                                    <div className="product-badge">
                                                        <ul>
                                                            <li className="badge-1">Hot</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </a>

                                            <div className="product-info">
                                                <h2 className="product-title"><a>Sourlmate {img.imageName}</a></h2>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                        <div className="btn-wrapper text-center">
                            <a href='/gallery' className="btn btn-transparent btn-border">View More +</a>
                        </div>
                    </div>
                </div>

                <div className='section-bg-light pt-60 pb-60'>
                    <BusinessClient />
                </div>

                <div className="section-bg pt-50 pb-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="ltn__feature-item-box-wrap ltn__feature-item-box-wrap-2  ltn__border section-bg-6">
                                    <div className="ltn__feature-item ltn__feature-item-8">
                                        <div className="ltn__feature-icon">
                                            <img src="img/svg/8-trolley.svg" alt="#" />
                                        </div>
                                        <div className="ltn__feature-info">
                                            <h4>Free Delivery</h4>
                                            <p>Purchase over $100</p>
                                        </div>
                                    </div>
                                    <div className="ltn__feature-item ltn__feature-item-8">
                                        <div className="ltn__feature-icon">
                                            <img src="img/svg/9-money.svg" alt="#" />
                                        </div>
                                        <div className="ltn__feature-info">
                                            <h4>High Valued</h4>
                                            <p>Valued flowers</p>
                                        </div>
                                    </div>
                                    <div className="ltn__feature-item ltn__feature-item-8">
                                        <div className="ltn__feature-icon">
                                            <img src="img/svg/10-credit-card.svg" alt="#" />
                                        </div>
                                        <div className="ltn__feature-info">
                                            <h4>Payment</h4>
                                            <p>Please contact us</p>
                                        </div>
                                    </div>
                                    <div className="ltn__feature-item ltn__feature-item-8">
                                        <div className="ltn__feature-icon">
                                            <img src="img/svg/11-gift-card.svg" alt="#" />
                                        </div>
                                        <div className="ltn__feature-info">
                                            <h4>Celebration card</h4>
                                            <p>free with flowers</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterNav />

            <div className="ltn__modal-area ltn__quick-view-modal-area">
                <div className="modal fade" id="quick_view_modal" tabIndex={-1}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    <i className="fas fa-times" />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="ltn__quick-view-modal-inner">
                                    <div className="modal-product-item">
                                        <div className="row" style={{ marginTop: 20 }}>
                                            <div className="col-lg-6 col-12">
                                                <div className="modal-product-img" style={{ minHeight: 100 }} >
                                                    {((selectProduct?.imageSrc?.length ?? 0) <= 1)
                                                        ? <img src={selectProduct?.imageSrc[0]} alt="#" />
                                                        : <ImageGallery showThumbnails
                                                            showFullscreenButton={false}
                                                            thumbnailPosition={'left'}
                                                            showPlayButton={false}
                                                            showNav={false}
                                                            items={selectProduct!.imageSrc.map(src => {
                                                                return {
                                                                    original: src,
                                                                    thumbnail: src
                                                                };
                                                            })} />

                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="modal-product-info shop-details-info pl-0">
                                                    <h3>{selectProduct?.productName}</h3>
                                                    <h5 className='mt-10'>{selectProduct?.productType}</h5>
                                                    {selectProduct?.productType === ProductType.SetBouquets &&
                                                        <div className="modal-product-meta ltn__product-details-menu-1 mb-20">
                                                            <ul>
                                                                <li>
                                                                    <div className="ltn__size-widget clearfix mt-25">
                                                                        <ul>
                                                                            <li><a className="d-meta-title">Small:NZD ${selectProduct?.price.split(',')[0]}.00</a></li>
                                                                            <li><a className="d-meta-title">Medium:NZD ${selectProduct?.price.split(',')[1]}.00</a></li>
                                                                            <li><a className="d-meta-title">Large:NZD ${selectProduct?.price.split(',')[2]}.00</a></li>
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    }
                                                    {selectProduct?.productType !== ProductType.SetBouquets &&
                                                        <div className="product-price-ratting mb-20">
                                                            <ul>
                                                                <li>
                                                                    <div className="product-price">
                                                                        <span>{selectProduct?.price}</span>
                                                                    </div>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    }
                                                    <div className="modal-product-brief">
                                                        <p>Eco-friendly flowers are a beautiful solution for the thoughtful gift giver.</p>
                                                    </div>
                                                    {!!!showCode && <div className="ltn__product-details-menu-2 product-cart-wishlist-btn mb-30">
                                                        <ul>
                                                            <li>
                                                                <a onClick={() => setShowCode(true)} className="theme-btn-1 btn btn-effect-1 d-add-to-cart">
                                                                    <span>Order Now</span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>}
                                                    {showCode && <div>
                                                        <h5 className='mt-10'>Scan & Contact</h5>
                                                        <div className='qr-row'>
                                                            <div>
                                                                <h4 className="footer-title text-center">Instangram</h4>
                                                                <img className='code-image' src={insImage} />
                                                            </div>
                                                            <div>
                                                                <h4 className="footer-title text-center">Wechat</h4>
                                                                <img className='code-image' src={wechatImage} />
                                                            </div>
                                                        </div>
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
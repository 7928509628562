import banner1 from 'Images/Slider/1.jpg';
import banner2 from 'Images/Slider/2.jpg';

const Slider = () => {
    return (
        <div className="ltn__slider-area ltn__slider-3 ltn__slider-6 plr--0">
            <div className="ltn__slide-home slick-slide-arrow-1 slick-slide-dots-1 arrow-white--">
                <div className="ltn__slide-item ltn__slide-item-8 ltn__slide-item-9 text-color-white bg-image bg-overlay-theme-black-40"
                    data-bg={banner1}>
                    <div className="ltn__slide-item-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 align-self-center">
                                    <div className="slide-item-info">
                                        <div className="slide-item-info-inner ltn__slide-animation">
                                            <div className="slide-item-info">
                                                <div className="slide-item-info-inner ltn__slide-animation">
                                                    <h6
                                                        className="slide-sub-title ltn__secondary-color slide-title-line-2 animated">
                                                        Flower &amp; Soulmate</h6>
                                                    <h2 style={{ color: '#c7c7c7' }} className="slide-title animated ">Find whichever<br /> suits you</h2>
                                                    <div className="slide-brief animated">
                                                        <p>Flowers are the music of the ground. From Earth&apos;s lips spoken without sound.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ltn__slide-item ltn__slide-item-8 text-color-white---- bg-image bg-overlay-theme-black-40"
                    data-bg={banner2}>
                    <div className="ltn__slide-item-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 align-self-center">
                                    <div className="slide-item-info">
                                        <div className="slide-item-info-inner ltn__slide-animation">
                                            <div className="slide-item-info">
                                                <div className="slide-item-info-inner ltn__slide-animation">
                                                    <h6
                                                        className="slide-sub-title ltn__secondary-color slide-title-line-2 animated">
                                                        Flower &amp; Soulmate</h6>
                                                    <h2 style={{ color: '#c7c7c7' }} className="slide-title animated ">Find whichever<br /> suits you</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Slider;
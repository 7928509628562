import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ComingSoon, Home, AboutUs, Contact, NotFound, Gallery, Events } from './Pages';

function App() {
    return (
        <>
            <div className="preloader d-none" id="preloader">
                <div className="preloader-inner">
                    <div className="spinner">
                        <div className="dot1" />
                        <div className="dot2" />
                    </div>
                </div>
            </div>

            <Router>
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/aboutus" component={AboutUs} />
                    <Route path="/gallery" component={Gallery} />
                    <Route path="/events" component={Events} />
                    <Route path="/contact" component={Contact} />
                    <Route component={NotFound} />
                </Switch>
            </Router>
        </>
    );
}

export default App;

import { Helmet } from 'react-helmet';
import { HeaderNav, FooterNav } from '../../Components';

const NotFound = () => {
    return (
        <div className="body-wrapper">

            <Helmet>
                <title>Soulmate Flower Design - Home</title>
            </Helmet>
            <HeaderNav flex={false} />

            <div className="ltn__utilize-overlay" />

            <div className="ltn__404-area ltn__404-area-1 mt-120 mb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="error-404-inner text-center">
                                <h1 className="error-404-title">404</h1>
                                <h2>Page Not Found!</h2>
                                <p>Oops! The page you are looking for does not exist. It might have been moved or deleted.</p>
                                <div className="btn-wrapper">
                                    <a href="/" className="btn btn-transparent"><i className="fas fa-long-arrow-alt-left" /> BACK TO HOME</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <FooterNav />

        </div>
    );
};
export default NotFound;
import { FooterNav, HeaderNav } from '../../Components';

const Events = () => {
    return (
        <>

            Events

        </>
    );
};
export default Events;
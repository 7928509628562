import { Helmet } from 'react-helmet';
import { BreadCrumb, FooterNav, HeaderNav } from '../../Components';
import address from 'Images/address.jpg';

const Contact = () => {
    return (
        <>
            <Helmet>
                <title>Soulmate Flower Design - Contact</title>
            </Helmet>
            <HeaderNav />
            <BreadCrumb title={'Contact Us'} list={['Home', 'Contact Us']} />

            <div className="ltn__contact-address-area pt-100 pb-40">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            {/* <div className="ltn__contact-address-item ltn__contact-address-item-4 box-shadow">
                                <div className="ltn__contact-address-icon">
                                    <i className="icon-location-pin" />
                                </div>
                                <h3>Pick Up Address</h3>
                                <p>81 Ivanhoe Road, Grey Lynn<br />Auckland 1021</p>
                            </div> */}
                        </div>
                        <div className="col-lg-3">
                            <div className="ltn__contact-address-item ltn__contact-address-item-4 box-shadow">
                                <a href="tel:09 2122528">
                                    <div className="ltn__contact-address-icon">
                                        <i className="icon-phone" />
                                    </div>
                                    <h3>Phone Number</h3>
                                    <p>09 2122528</p>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="ltn__contact-address-item ltn__contact-address-item-4 box-shadow">
                                <a href="mailto:info@soulmateflowerdesign.co.nz">
                                    <div className="ltn__contact-address-icon">
                                        <i className="icon-envelope" />
                                    </div>
                                    <h3>Email</h3>
                                    <p>info@soulmateflowerdesign.co.nz</p>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            {/* <div className="ltn__contact-address-item ltn__contact-address-item-4 box-shadow">
                                <div className="ltn__contact-address-icon">
                                    <i className="icon-speedometer" />
                                </div>
                                <h3>Pick Up Hours</h3>
                                <p>Mon to Fri 8:30am to 8:30pm<br />
                                    Sat & Sun: 9:30am to 5:30pm<br />
                                    Public holiday please check our website and Instagram</p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="ltn__banner-area pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="ltn__banner-item">
                                <div className="ltn__banner-img">
                                    <a><img src={address} alt="Banner Image" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="ltn_google-map-area pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="google-map">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3191.9482588408846!2d174.73313271602632!
                                3d-36.867657288271786!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d47a65e8032e1%3A0x4a55ed422602881e!
                                2s81%20Ivanhoe%20Road%2C%20Grey%20Lynn%2C%20Auckland%201021!5e0!3m2!1sen!2snz!4v1622761798735!5m2!1sen!2snz"
                                    width="100%" height="100%" frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0} />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}


            <FooterNav />
        </>
    );
};
export default Contact;